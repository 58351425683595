<template>
  <LiefengContent>
    <template v-slot:title> 短信开通申请管理 </template>
 
    <template v-slot:toolsbarRight>
      
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.name"
        placeholder="姓名"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.mobile"
        placeholder="手机号"
        style="width: 150px;margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Select style="width:100px; margin-right: 10px" v-model="searchData.status" placeholder="请选择状态" clearable>
        <Option v-for="(item,index) in statusList" :key="index" :value="item.value">{{item.label}}</Option>
      </Select>
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="ios-search"
        @click="getList"
        >查询</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="success"
        icon="ios-refresh"
        @click="rest"
        >重置</Button
      >
      
     

    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        height="200"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
      ></LiefengTable>
      <!-- 驳回框 -->
   
    </template>
  </LiefengContent>
</template>

<script>
//@route("/residentindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
   
  data() {
    return {
      loading:false,

      statusList: [
        {label:'全部',value:''},
        { label: "已开通", value: "1" },
        { label: "待开通", value: "2" },
      ],
      
      talbeColumns: [
        {
          title: "机构名称",
          align: "center",
          width: 100,
        },
        {
          title: "申请人姓名",
          // key: "sex",
          align: "center",
          width: 80,
        },
        {
          title: "联系电话",
          width: 130,
          align: "center",
        },
        {
          title: "开通状态",
          align: "center",
          width: 150,
          
        },
        {
          title: "操作",
          slot: "action",
          width: 130,
          align: "center",
          render: (h, params) => {
            return h("div", [
                h(
                "DropdownItem",
                {
                  style: {
                    textAlign: "center",
                  },

                  nativeOn: {
                    click: () => {
                      this.througth(params.row);
                    },
                  },
                },
                "开通"
              ),
          ]);
            
          },
        },
      ],
      tableData: [],
      selection: [],
      showDo: true,
      hideEdit: true,
      hidenBread: true,
      hideDel: true,
      hideSearch: false,
      curPage: 1,
      pageSize: 20,
      orgCode: "",
      rejectModal: false,
      rejectParams: {},
      familyMemberId: "",
      formValidate: {
        reason: "",
      },
      ruleValidate: {
        reason: [
          {
            required: true,
            message: "驳回原因不能为空",
            trigger: "blur",
          },
        ],
      },
      inviterRelationshipList:[],
    };
  },
  created(){
  },
  methods: {
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
   
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
     margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
/deep/.ivu-form-item-label{
  width: 120px !important;
}
/deep/.ivu-form-item-content{
  margin-left: 120px !important;
}

//  ivu-select-single ivu-select-default
</style>